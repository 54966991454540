/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    pre: "pre",
    code: "code",
    h3: "h3",
    ul: "ul",
    li: "li",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "JavaScript Objects"), "\n", React.createElement(_components.p, null, "objects have methods and properties."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "let names = [\"lucas\", \"luc\", \"James\", \"Rafael\"]\n\n// lenght method\nconsole.log(name.length)\n// 4\n")), "\n", React.createElement(_components.h3, null, "Primitives"), "\n", React.createElement(_components.p, null, "Primitive do not have methods. But, javascript is intuitive. This means that JavaScript wraps primitives in objects which enables methods. Thus, making a given primitive to behave like an object."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "booleans"), "\n", React.createElement(_components.li, null, "strings"), "\n", React.createElement(_components.li, null, "numbers"), "\n"), "\n", React.createElement(_components.h2, null, "First Object"), "\n", React.createElement(_components.h4, null, "Encapsulation"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "let userOne = {\n  email: \"ben@earth.com\",\n  name: \"ben\",\n}\n\n// ^ this is called encapulation\n\nconsole.log(userOne.name)\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "let userOne = {\n  email: \"ben@earth.com\",\n  name: \"ben\",\n  login() {\n    console.log(this.email, \"has logged in\")\n  },\n  logOut() {\n    console.log(this.email, \"has logged out\")\n  },\n}\n\nconsole.log(userOne.login())\n// ben@earth has logged in\n")), "\n", React.createElement(_components.p, null, "// We can change property values these values can be dynamic\n// We can add new methods to objects"), "\n", React.createElement(_components.p, null, "// Creating multiple instances of the same type"), "\n", React.createElement(_components.h2, null, "Classes In ES6 and >"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "class User {\n  constructor(email, name) {\n    // properties\n    this.email = email\n    this.name = name\n  }\n  // methods\n  login() {\n    console.log(this.email + \" just logged in\")\n  }\n}\n\nvar userOne = new User(\"jim@email.com\", \"Jim\")\nvar userTwo = new User(\"jeff@email.com\", \"Jeff\")\n\n// the 'new' keyword\n// - creates a new empty object {}\n// - sets the value of 'this' to be the new empty object\n// - calls the constructor method\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
